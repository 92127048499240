@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
    font-family: 'Georgia';
    src: url(./font/georgia.ttf) format('truetype');
    font-weight: 400;
    
    font-display: swap;
  }
  @font-face {
    font-family: 'Georgia';
    src: url(./font/georgiab.ttf) format('truetype');
    font-weight: 500;
    
    font-display: swap;
  }

body {
  @media (min-width: 1280px) {
    h1 {
      font-family: 'Georgia';
      font-weight: 700;
      font-size: 64px;
      line-height: 137%;
    }
    h3 {
      font-family: 'Georgia';
      font-weight: 500;
      font-size: 48px;
      line-height: 57px;
    }
    h4 {
      font-family: 'Georgia';
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
    }
    h5 {
      font-family: 'Georgia';
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
    h6 {
      font-family: 'Georgia';
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    & button {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: var(--white);
    }
    & a {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 16px;
    }
  } 
  @media (min-width: 1536px) {
    h1 {
    font-family: 'Georgia';
    font-weight: 700;
    font-size: 64px;
    line-height: 137.5%;
  }
  } 
}
