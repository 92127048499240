:root{
    --purple: #6062F6;
    --purple-1000: #35355B;
    --purple-400: #B9B9E1;
    --purple-300: #D3D3EB;
    --purple-200: #D6D2E0;
    --purple-100: #F5F4FE;
    --purple-50: #fbfaff;
    --white: #FFFFFF;
    --black: #000000;
    --boltgun-metal-700: #2C2C2C;
    --boltgun-metal-600: #333232;
    --boltgun-metal-500: #3A3939;
    --boltgun-metal-400: #7D7C7C;
    --boltgun-metal-300: #A7A7A7;
    --boltgun-metal-200: #C8C8C8;
    --error: #FF434E;
}